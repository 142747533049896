<!-- 申请退款 -->
<template>
	<div :class="themeClass" class="min-width1100 margin-t-20">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum border-F2F2F2">
			<div class="evaluate-title font-size16 font-weight700 padding-lr-15">
				申请退款
			</div>
			<div class="order-top bg-F2F4FD padding-lr-15">
				<span class="margin-r-30">{{ orderDetails.fBeginDate | dateFormat }}</span>
				<span class="margin-r-30">订单号：<span class="color-theme">{{ orderDetails.fOrderNumber }}</span></span>
				<span><span class="color-theme">{{ orderDetails.fShopName }}</span></span>
			</div>
			<div class="order-bottom padding-lr-15 padding-tb-25 flex-row-space-between-center border-bottom-F2F2F2 margin-b-10">
				<div class="flex-row">
					<div class="img-box radius10 flex-row-center-center margin-r-10">
						<img :src="orderDetails.imageUrl" alt="" class="img-style">
					</div>
					<div class="flex-colum">
						<div class="margin-r-20 margin-b-5">{{ orderDetails.fGoodsFullName }}</div>
						<div class="flex-row font-size12 margin-b-5">
							<!-- <div class="distributionMode-box linear-gradient padding-lr-5 font-color-fff margin-r-10">企业专项</div> -->
							<div v-if="orderDetails.fShopTypeID == 1" class="label linear-gradient font-color-fff text-center bg-40C6A9 margin-r-5">{{orderDetails.fShopTypeName}}</div>
							<div class="modeOfDelivery-box border-theme color-theme padding-lr-5">{{orderDetails.fDelivery}}</div>
						</div>
						<div class="font-color-ae">工程：{{ orderDetails.fProjectName == "" ? "无" : orderDetails.fProjectName }}</div>
					</div>
				</div>
				<div class="flex-row bottom-right">
					<div class="padding-lr-37 border-lr-F2F2F2">X{{ orderDetails.fPlanAmount }}{{ orderDetails.fUnitName }}</div>
					<div v-if="orderDetails.fTradingModelID ==5" class="padding-lr-37 font-size13 font-weight700">{{ orderDetails.fUsePoint}}积分</div>
					<div v-else class="padding-lr-37 font-size13 font-weight700">￥{{ orderDetails.fGatheringMoney }}</div>
					
				</div>
			</div>
			<div class="form-box padding-15">
				<el-form ref="form" :model="form" :rules="rules" label-width="80px">
					
					<el-form-item label=" 应退积分" v-if="orderDetails.fTradingModelID ==5">
						<el-input v-model="RefundPoint" disabled></el-input>
						<!-- <span>{{RefundPoint}}</span> -->
					</el-form-item>
					<el-form-item label="退款金额" v-else>
						<el-input v-model="form.fRefundMoney" disabled></el-input>
					</el-form-item>
					<el-form-item label="退款原因" prop="reason">
						<el-select v-model="form.reason" placeholder="请选择退款原因">
							<el-option v-for="(item,index) in refundRemark" :key="index" :label="item" :value="item"></el-option>
						</el-select>
						<!-- <el-input v-model="form.reason"></el-input> -->
					</el-form-item>
				</el-form>
			</div>
			<div class="padding-lr-15 margin-b-15">
				<div class="pointer evaluate-btn text-center background-color-theme " @click="submitClick">
					提交申请
				</div>
			</div>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="20%" class="dialog">
			<div class="flex-colum font-size13">
				<!-- <div class="margin-b-5">优惠券：不退</div> -->
				<div class="margin-b-5">实退金额：¥{{modelContent.fRefundMoney}}</div>
				<div v-if="modelContent.fIsShowFTMoney == 1" class="margin-b-5">实退货款金额：¥{{modelContent.fRefundGoodsMoney}}</div>
				<div v-if="modelContent.fIsShowFTMoney == 1" class="margin-b-5">实退运费金额：¥{{modelContent.fRefundFMoney}}</div>
				<div class="margin-b-5">实退积分：{{modelContent.fRefundPoint}}个<span v-if='modelContent.fRemark == 1' style="color:red">（跨年订单积分不退回）</span></div>
				<div>{{modelContent.fDispRefundPath}}</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit" :loading="isLoading">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	import UploadImgList from "@/components/upload/UploadImgList.vue";
	export default {
		components: {
			breadcrumb,
			UploadImgList
		},
		data() {
			return {
        isLoading: false,
				dialogVisible: false,
				modelContent: {},
				fRefundMoney: '', //退款金额
				orderID: null,
				orderDetails: {},
				rules: {
					reason: [{
						required: true,
						message: "退款原因不能为空",
						trigger: "blur,change",
					}]
				},
				form: {
					fRefundMoney: 0,
					reason: '',
					
				},
				refundRemark: [],
				RefundPoint:0
			}
		},
		async mounted() {
			this.orderID = await this.$route.query.id;
			this.getRefundMoney();
			this.getOrderDetails();
			this.getRefundRemark();
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			getRefundMoney() {
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/computer-refund-bill-by-order-id", {
					fOrderID: this.orderID,
					fAppTypeID: this.fAppTypeID
				}).then(res => {
					if (res.message == "") {
						console.log(res)
						this.form.fRefundMoney = res.obj.fRefundMoney;
						this.RefundPoint=res.obj.fRefundPoint
						this.modelContent = res.obj;
					} else {
						this.tipsInfo(res.message)
					}
				})
			},
			// 提交退款
			submitClick() {
				this.$refs["form"].validate((valid) => {
					if (!valid) {
						return;
					}
					this.dialogVisible = true;
				})
			},
			submit() {
        this.isLoading = true
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/create-order-refund-bill", {
					fOrderID: this.orderID,
					fRemark: this.form.reason,
					fAppTypeID: this.fAppTypeID
				}).then(res => {
					if (res.obj.status == true) {
						this.isLoading = false
						this.dialogVisible = false;
            			this.$message('退款申请已提交');
            			this.$router.go(-1);
					} else {
						this.$message(res.obj.message);
					}
				}).catch(err => {

				})
			},
			// 获取订单信息
			getOrderDetails() {
				this.ApiRequestPostNOMess("api/mall/ebsale/order/get", {
					id: this.orderID
				}).then(res => {
					res.obj.fDeliveryAddress = res.obj.fDeliveryAddress.replace(/\\/g, '');
					this.orderDetails = res.obj;
					this.getOrderPicture();
				}).catch(err => {

				})
			},
			// 获取退款理由
			getRefundRemark() {
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/get-refund-remark", {}).then(res => {
					this.refundRemark = res.obj;
				}).catch(err => {

				})
			},
			//获取商品图片
			getOrderPicture() {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get', {
					id: this.orderDetails.fGoodsID
				}).then(
					res => {
						this.orderDetails.imageUrl = res.obj.fPicturePath;
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					},
					error => {}
				);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.bg-40C6A9 {
		background-color: #40C6A9;
	}

	.label {
		height: 16px;
		line-height: 16px;
		padding: 0 5px;
	}

	.evaluate-title {
		height: 44px;
		line-height: 44px;
	}

	.order-top {
		height: 35px;
		line-height: 35px;
	}

	.order-bottom {}

	.img-box {
		width: 65px;
		height: 65px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
	}

	.img-style {
		width: 44px;
		height: 59px;
	}

	.distributionMode-box {
		height: 16px;
		line-height: 16px;
	}

	.modeOfDelivery-box {
		height: 14px;
		line-height: 14px;
	}

	.linear-gradient {
		background-image: linear-gradient(to right, #FF0000, #FF9999);
	}

	.padding-lr-37 {
		padding-left: 37px;
		padding-right: 37px;
	}

	.bottom-right {
		height: 65px;
		// line-height: 65px;
	}

	.line-height24 {
		line-height: 24px;
	}

	.evaluate-btn {
		width: 235px;
		height: 45px;
		line-height: 45px;
	}

	.form-box::v-deep {
		.el-input {
			width: 330px !important;
		}

		.el-textarea {
			width: 330px !important;

			.el-textarea__inner {
				min-height: 67px !important;
			}
		}
	}

	.dialog::v-deep {
		.el-dialog__body {
			padding: 0 20px !important;
		}
	}
</style>
